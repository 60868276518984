<template>
  <div class="cancellation">
    账号注销页面
  </div>
</template>

<script>
export default {
  name:'Cancellation'
}
</script>

<style>

</style>